import * as React from 'react'
import { Link, graphql } from 'gatsby'

import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts'

const IndexPage = ({ data }) => (
  <IndexLayout>
    <Page>
      <Container>
        <h1>Hi people</h1>
        <p>Welcome to your new Gatsby site.</p>
        <p>Now go build something great.</p>
        {data.allDirectory.edges.map(({ node }) => (
          <div>
            <Link to={node.name}>{node.name}</Link>
          </div>
        ))}
      </Container>
    </Page>
  </IndexLayout>
)

export const query = graphql`
  query MyQuery {
    allDirectory(filter: { parent: {}, name: { ne: "workspace" } }) {
      edges {
        node {
          name
        }
      }
    }
  }
`

export default IndexPage
